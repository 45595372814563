.page {
  font-family: "Muli", sans-serif;
  background: $black;
  color: $white;
}

.raptor-page-container {
  padding: 90px 0;
}

.raptor-page-hero {
  text-align: center;
  height: 400px;
}

.raptor-page-hero-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.raptor-page-hero-container-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
}

.raptor-page-hero-container-content p {
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 300;
}

.raptor-homepage-hero {
  padding: 100px 0;
  text-align: center;
}

.raptor-homepage-hero-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.raptor-homepage-hero-container-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.raptor-homepage-hero-container-content h2 {
  color: $brand-primary;
  margin: 0 0 15px;
  font-weight: 300;
}

.raptor-homepage-hero-container-content img {
  margin-bottom: 15px;
}

.raptor-page-container .panel,
.raptor-page-container .panel-default > .panel-heading {
  background: none;
  border: none;
}

.raptor-page-container .panel {
  border-top: 1px solid $brand-primary;
  box-shadow: none;
}

.raptor-page-container .panel-group {
  padding: 0 15px;
}

.raptor-page-container
  .panel-default
  > .panel-heading
  + .panel-collapse
  > .panel-body {
  border: none;
}

.raptor-page-container .panel-group .panel {
  border-radius: 0;
}

.raptor-page-container .panel-heading {
  padding: 30px 0px;
}

.raptor-page-container .panel-heading a {
  text-decoration: none;
}

.raptor-page-container .panel-title {
  color: $brand-primary;
  text-transform: uppercase;
  font-size: 30px;
}

.raptor-page-container .panel-group .panel-heading .panel-title a:after {
  color: $brand-primary;
  right: 0;
  content: "\2212";
  font-family: Glyphicons Halflings;
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
}

.raptor-page-container
  .panel-group
  .panel-heading
  .panel-title
  a.collapsed:after {
  content: "\002b";
}

.raptor-page-container .panel-body {
  padding: 0 0 30px;
}

.raptor-page-container .panel-body ul:last-child,
.raptor-page-container .panel-body p:last-child {
  margin-bottom: 0;
}

.raptor-page-container .panel-body ul {
  padding: 0;
  list-style: none;
  margin-top: 30px;
}

.raptor-page-container .panel-body ul li {
  min-height: 32px;
  margin-bottom: 20px;
  background: url("../images/raptor-eye-icon.png");
  background-repeat: no-repeat;
  background-size: 32px;
  padding-left: 42px;
  padding-top: 8px;
}

.raptor-page-container .panel-group .panel + .panel {
  margin: 0;
}

.raptor-page-section {
  margin: 0 auto 30px;
  padding: 0 15px 35px;
  text-align: center;
  max-width: 800px;
  border-bottom: 1px solid $brand-primary;
}

.raptor-page-section:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.raptor-page-section h2 {
  color: $brand-primary;
  margin: 0 0 15px;
  font-weight: 300;
}

.raptor-page-section p:last-child {
  margin-bottom: 0;
}

.raptor-page-section img {
  margin-bottom: 15px;
}

.raptor-page-section-contact {
  text-align: left;
  padding: 0 15px 20px;
}

.contact-us {
  .raptor-page-container {
    padding: 75px 0;
  }
}

.raptor-page-section-contact h2 {
  margin: 0;
}

.raptor-page-section-contact h3 {
  color: $white;
  margin: 0 0 15px;
}

.raptor-page-section-contact p {
  margin: 0;
  font-size: 20px;
}

.raptor-page-section-contact i {
  margin-right: 15px;
}

.raptor-page-section-contact .col-md-6 {
  border-right: 1px solid $brand-primary;
  text-align: center;
}

.raptor-ourservices-page-section {
  border: none;
}

.flexslider {
  border: none;
  margin: 0;
  background: $black;
}

.flex-direction-nav .flex-next {
  background: url("../images/raptor-slider-next.png") !important;
}

.flex-direction-nav .flex-prev {
  background: url("../images/raptor-slider-prev.png") !important;
}

.flex-direction-nav a.flex-prev:before {
  content: "" !important;
}

.flex-direction-nav a.flex-next:before {
  content: "" !important;
}

.flex-prev,
.flex-next {
  font-size: 0;
}

.raptor-flexslider-slide {
  height: 400px;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

//Policies

.page-template-template-policy .raptor-page-section {
  text-align: left;
  max-width: 960px;
}

.page-template-template-policy .raptor-page-section {
  h1,
  h2,
  h3,
  h4 {
    margin: 3rem 0 1.5rem;
    color: $brand-primary;
  }

  h1 {
    margin-top: 0;
  }
}

.cookie-policy {
  h2 {
    margin-top: 0 !important;
  }
}

/*Media Queries*/

@media (max-width: 991px) {
  .raptor-page-section-contact .col-md-6 {
    margin-bottom: 22.5px;
    border: none;
  }
  .raptor-page-section-contact .col-md-6:last-child {
    margin-bottom: 0;
  }

  .raptor-page-container .panel-group .panel-heading .panel-title a:after {
    color: $brand-primary;
    right: 0;
    content: "\2212";
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    float: right;
    display: none;
  }

  .raptor-page-container
    .panel-group
    .panel-heading
    .panel-title
    a.collapsed:after {
    content: "\002b";
    display: none;
  }
}

.our-services {
  .raptor-page-section {
    max-width: 650px;
  }
}

.raptor-col {
  margin: 15px 0;
}

@media (max-width: 768px) {
  .raptor-page-container {
    padding: 45px 0;
  }
  .raptor-page-hero {
    height: 200px;
  }
  .raptor-flexslider-slide {
    height: 200px;
  }
  .raptor-ourservices-page-section {
    margin-bottom: 0;
  }
  .nav {
    padding: 20px 0;
  }
  .nav > li > a {
    padding: 0 20px;
  }
}
