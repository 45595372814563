.downloads{
	.raptor-page-section{
		p{
			margin: 0 auto;
			max-width: 550px;
		}
	}
}

.raptor-downloads{
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left;
	
	li{
		border-bottom: 1px solid #2a2522;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin: 0 0 1em;
		padding: 0 0 1em;
		
		&:last-child{
			margin: 0;
		}
		
		i{
			margin: 0 0.25em 0 0;
		}
		
		span{
			a{
				&:nth-child(2){
					margin: 0 0 0 0.75em;
				}	
			}
		}
	}
}

@media (max-width: 768px) {
	.raptor-downloads{
		li{
			flex-direction: column;
			
			span{
				&:first-child{
					margin: 0 0 0.25em;
				}
			}
		}
	}
}