header{
	background: url('../images/raptor-bg-pattern.png');
	background-size: 200px;
}

#raptor-header-brand{
	padding: 30px 0;
	text-align: center;
}

#raptor-header-nav{
	border-top: 1px solid $brand-primary;
	
	.container{
		width: 100%;
	}
}

#raptor-header-brand-logo{
	width: 350px;
}

/*Navigation*/

@media (min-width: 768px){
	.nav-collapse{
		text-align: center;
		
		ul{
			font-size: 0;
			
			li{
				font-size: 20px;
				display: inline-block;
				padding: 30px;
				width: auto;
				
				&:first-child{
					padding-left: 0;
				}
				
				&:last-child{
					padding-right: 0;
				}
				
				a{
					color: $white;
					text-decoration: none !important;
					transition: 0.3s ease-out color;
					
					&:hover{
						color: $brand-primary;
					}
				}
			}
		}
	}
}

@media (max-width: 991px){

	a.nav-toggle{
		color: $white;
		display: block;
		padding: 15px;
		text-align: center;
		text-decoration: none !important;
		text-transform: uppercase;
		transition: 0.3s ease-out color;
		
		&:after{
			font-family: "Font Awesome 5 Pro";
			content: "\f0c9";
			margin: 0 0 0 0.5rem;
		}
		
		&:hover{
			color: $brand-primary;
		}
	}
	
	a.nav-toggle.active{
		&:after{
			content: "\f00d";
		}
	}
	
	.nav-collapse{
		text-align: center;
		
		ul{
			
			li{
				
				a{
					color: $white;
					display: block;
					padding: 5px 0;
					text-decoration: none !important;
					transition: 0.3s ease-out color;
					
					&:hover{
						color: $brand-primary;
					}
				}
				
				&:last-child{
					padding-bottom: 15px;
				}
			}
		}
	}
	
}

@media (max-width: 767px){

	#raptor-header-brand-logo{
		width: 250px;
	}
}