a{
	background: none !important;
}

.row-eq-height{
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 991px){
	.row-eq-height{
		display: inherit;
	}
}